.form-register .btn-registerEmp {
   width: 50%;
}

.form-register .floating-control:has(#password, #confirmPassword) {
   border-radius: 0.25rem 0 0 0.25rem
}

.form-register .input-group-text {
   border-radius: 0 .375rem .375rem 0;
}

@media (max-width: 575.98px) {
   .form-register .registerEmp {
      width: 100%;
   }
}