.emp_account .sub-title {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-grey);
}

:is(.emp_account, #changeProfileModal) .account-photo {
  width: 200px;
  height: 200px;
}

:is(.emp_account, #changeProfileModal) .account-photo img {
  border: 1px solid var(--color-pri-blue);
  border-radius: 50%;
  object-fit: cover;
}

.emp_account .floating-label {
  background-color: var(--color-white);
}

.phoneNumber-box .phoneNumber {
  flex-grow: 1;
}

.emp_account .change-text {
  font-weight: 600;
  color: var(--color-pri-blue);
  text-decoration: underline;
  cursor: pointer;
}

.emp_account .floating-input {
  color: var(--color-grey);
}

#accountModal .floating-label {
  background-color: var(--color-white);
}

#accountModal button {
  width: min(100%, 150px);
}

.close-icon,
.close-icon-red,
.close-icon-blue {
  background: var(--color-black);
  color: var(--color-white);
  font-size: 30px;
  margin: 0;
  padding: 7px;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;

  top: 0;
  right: 0;
  transform: translate(35%, -30%);
}

/*.close-icon-red {
   background-color: var(--color-black);
}

.close-icon-blue {
   background-color: var(--color-black);
}*/

.message {
  font-size: 22px;
  color: var(--color-pri-blue);
}

#changeProfileModal button {
  width: min(100%, 150px);
}

.emp_account .floating-control:has(.floating-input:focus) {
  border: 1px solid var(--color-grey);
}

.emp_account .floating-input:focus ~ .floating-label {
  color: var(--color-black);
}
