#fileUploder__modal .modal-content {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.input__file__wrapper {
  padding: 3px;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
}

.input__file {
  overflow: hidden;
  border: 1px solid black;
  text-align: center;
  border-radius: 3px;
  white-space: nowrap;
  align-self: self-start;
  flex-basis: 100px;
}

.input__fileName {
  flex-basis: calc(100% - 16px - 100px);
  word-break: break-all;
}

.btn__AddMoreFiles {
  align-self: self-start;
  border: none;
  background-color: white;
}

#documentModal.document-modal-dialog {
  width: min(700px, 95%) !important;
  max-width: none !important;
}

.unVerifiedFile__wrapper,
.verifiedFile__wrapper {
  flex-basis: 350px;
}

.verifiedFile__wrapper {
  border-right: 1px solid #e9e5e4;
  padding-right: 10px;
  margin-right: 18px;
}

.modal:has(#viewModal) {
  background-color: rgba(0, 0, 0, 0.3);
}
.document__wrapper :is(.passport, .educationCertificates) {
  border-right: 1px solid #cdd2d5;
}

@media (max-width: 575.98px) {
  .unVerifiedFile__wrapper,
  .verifiedFile__wrapper {
    flex-basis: 0;
  }
  .verifiedFile__wrapper {
    border-right: none;
  }

  .document__wrapper :is(.passport, .educationCertificates) {
    border-bottom: 1px solid #cdd2d5;
    margin-bottom: 5px;
  }
}
