/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-view{
  display: none;
}

#documentModal .modal-backdrop.show {
  /* opacity: var(--bs-backdrop-opacity); */
  opacity: 0;
}
/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/**/
.requiredMark {
  position: relative;
}

#DataAlert {
  width: 30px;
  height: 30px;
}

#alert-dialog-description {
  font-size: 18px;
  color: black;
}

#documentModal .modal-backdrop.show {
  opacity: 0 !important;
}

.requiredMark::after {
  position: absolute;
  content: "*";
  color: red;
  font-size: 25px;
  top: 50%;
  left: 0;
  transform: translate(-15px, -35%);
}

.floating-input:focus {
  outline: none !important;
  border: none !important;
}

.pointer-events-none {
  pointer-events: none;
}

.min-h-100 {
  min-height: 100px;
}

.helperwish .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #010101;
}

/* Grey out Input*/
.read-only-input input,
.read-only-input .floating-label,
.read-only-input .floating-input:focus ~ .floating-label,
.read-only-input .floating-input,
.read-only-input,
.read-only-input svg,
.read-only-input .form-select
 {
  color: #a6a6a6;
  pointer-events: none;
}
.personalDetails .form-select{
min-height: 3.5rem;
border:1.5px solid var(--color-grey) !important;
}

.read-only-input .chip {
  background: black !important;
  border-radius: 5px;
}

.read-only-input .closeIcon,
.read-only-input .arrowDown,
.read-only-input .searchBox,
.read-only-input .react-datepicker__close-icon {
  display: none;
}

#EducationTable{
  box-shadow:5px 5px 6px #F0F0F0;
}

.read-only-input .form-select{
  appearance: none;
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
  background-image: none; /* Ensures no background arrow */
}

.divWidth {
  width: 85%;
}

.workExperienceTable .requiredMark::after {
  position: relative;
  left: -5px;
  top: 12px;
}

.workExperienceTable :is(.from-date, .to-date) {
  min-width: 120px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 8px 5px;
}

.workExperienceTable .duties {
  padding: 0;
  min-width: 180px;
}

.workExperienceTable .PresentDiv {
  padding: 0;
  min-width: 180px;
}

.workExperienceTable .duties .searchBox {
  padding: 0;
}

.workExperienceTable table tbody .rdtPicker {
  position: unset;
}

.workExperienceTable .table {
  --bs-table-bg: none !important;
  --bs-table-color:none !important;
}

.outline-0 {
  outline: 0;
}

.personalDetails .form-check-input:focus {
  box-shadow: none !important;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-x-24 {
  row-gap: 24px;
}



.w-40 {
  width: 40%;
}

/* custom margin bottom */
.cmb-40 {
  margin-bottom: 35px;
}

/* custom padding Y  */
.cpy-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* custom border radius */
.cbr-rd-50 {
  border-radius: 50% !important;
}

/* custom width  */
.cw-120 {
  width: 120px !important;
}
.cw-150{
  width: 150px !important;
}

.cw-160 {
  width: 160px !important;
}

.cw-200 {
  width: 200px !important;
}

.cw-90 {
  width: 90px !important;
}

.cw-100 {
  width: 120px !important;
}

.cw-210 {
  width: 210px !important;
}

.c-min-w-200 {
  min-width: 200px !important;
}

.tbl-td {
  white-space: nowrap;
  width: 1%;
}

.minH-auto {
  min-height: auto !important;
}

.border-R-transparent {
  border-right: 1px solid transparent;
}

/* personal detaills css start  */
.candidate_profile select {
  appearance: none;
}

.c-select {
  display: block;
  width: 100%;
  height: 40px;
  /* padding: .475rem 2.25rem .475rem .75rem; */
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #ced4da;
  outline: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.datepicker {
  height: 40px;
  border-radius: 0.375rem;
  border: 1px solid #a6a6a6;
}

.candidate_profile .arrowDown {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  pointer-events: none;
  border-left: 1px solid var(--bs-gray-500);
  padding-left: 5px;
}

.candidate_profile .floating-label {
  background-color: var(--color-white);
}

.candidate_profile main .form-check-input:checked {
  background-color: var(--color-pri-blue);
}

.candidate_profile main .form-select:focus {
  border: 2px solid var(--color-pri-blue);
  box-shadow: none;
}

.candidate_profile main :is(.icon-eye, .icon-change) {
  color: var(--color-pri-blue);
  font-size: 30px;
}

.candidate_profile main .icon-text {
  font-size: 10px;
  font-weight: 700;
}

.candidate_profile main .icon-change {
  color: var(--success);
}

.candidate_profile .uploadBtn {
  width: 260px;
}

.candidate_profile .profile_photo-img {
  width: 150px;
  height: 150px;
  border: 1px solid var(--color-pri-blue);
  border-radius: 50%;
  object-fit: cover;
}

.candidate_profile .mediaCrossIcon {
  color: #ef3333;
  cursor: pointer;
}

.fullPhotoWrapper {
  width: 150px;
  min-height: 150px;
  border: 1px solid var(--color-pri-blue);
}

.fullPhotoWrapper .fullPhoto-img {
  width: 100%;
  object-fit: cover;
}

.candidate_profile input#children {
  /*border-color: var(--color-pri-black);
   outline-color: var(--color-pri-black);*/
  /* border: 2px solid black;*/
  border: 1.5px solid var(--color-grey);
  text-align: center;
  width: 50px;
  padding: 0 10px;
}

.record-container .record-wrapper {
  width: 200px;
  border-radius: 4px;
}

.record-container .record-wrapper img {
  border-radius: 4px;
}

.candidate_profile .childAge .floating-control {
  width: 100px;
}

.candidate_profile .newChildAge .floating-control {
  width: 130px;
}

.candidate_profile .childAge .floating-label {
  left: 0.5rem !important;
}

.candidate_profile :is(.removeChildBtn, .addChildBtn) {
  cursor: pointer;
}

.btn-save,
.btn-cancel {
  width: min(200px, 100%);
}

.calendar-icon {
  font-size: 16px;
}

/*datepicker css*/
.react-datepicker__month-select,
.react-datepicker__year-select {
  color: #212529;
  border: 2px solid #ced4da;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--color-pri-blue);
  top: 13px;
}

/*-----------------------------------*/

/*multiselect custom css start*/
.multiSelect-container,
.multiSelect-workExperience-container {
  width: 100% !important;
}

.multiSelect-container {
  border-color: transparent !important;
}

.read-only-input
  :is(.multiSelect-option-badge, .multiSelect-workExperience-option-badge) {
  background-color: black !important;
}

.read-only-input
  :is(.multiSelect-remove-btn, .multiSelect-workExperience-remove-btn) {
  display: none !important;
}

.read-only-input
  :is(.multiSelect-clear-btn, .multiSelect-workExperience-clear-btn) {
  display: none !important;
}

.candidate_profile .chip {
  background: var(--color-pri-blue);
  border-radius: 5px;
}

.candidate_profile .floating-control .searchWrapper {
  border: 0;
}

.candidate_profile .multiSelectContainer input {
  width: 100px;
  padding-left: 10px;
}

/*multiselect custom css end*/
.yearsOfExperience :is(#year, #month) {
  text-align: center;
}

.yearsOfExperience :is(#year, #month) + label {
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Added by Namrata*/
.singaporeExperience :is(#singaporeYear, #singaporemonth) {
  text-align: center !important;
}

.singaporeExperience :is(#singaporeYear, #singaporemonth) + label {
  left: 50%;
  transform: translate(-50%, -50%);
}

.invalid {
  border: 2px solid var(--error) !important;
}

.icon_down_dir:before {
  content: unset !important;
}

.workExperience_duties .optionListContainer {
  position: unset;
}

.birthdayWrapper,
.maritalStatusWrapper {
  width: 260px;
}


.genderWrapper,
.nationalityWrapper {
  width: 230px !important;
}

.heightWrapper,
.weightWrapper {
  width: 230px;
}

.singaporeExperienceWpapper {
  width: 330px;
}

.yearsOfExperience {
  width: 300px;
}
.candidateType {
  width: 300px;
}
.oFFDays {
  width: 182px;
}

.btn-yourDocument {
  /* width: min(300px, 100%); */
  width:300px;
}

#yourDocSave,
#yourDocCancel {
  width: 140px;
}
.yourDocumentError{
margin-top: -9px;
}

.countryDiv {
  width: 240px !important;
}
.no-border {
  border: none !important;
  box-shadow: none; /* Optional: Removes any shadow if present */
}

#candidateType p,
#NewTypeCnd p,
.dropdown-text {
  font-size: 14px;
}

#candidateType p,
#NewTypeCnd p,
.dropdown-text {
  font-size: 14px;
}

#educationalDetails p,
#NewEducationDetails p,
#educationalDetails span,
#NewEducationDetails span {
  font-size: 14px;
}



.input-container {
  position: relative;
  margin: 0 auto;
}

/*.input-container:focus {
   border: 1px solid var(--color-pri-blue);
}*/

 input {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  box-sizing: border-box;
} 

.right-float {
  display: flex;
  position: absolute;
  top: 50%;
  /* Adjust the vertical position */
  right: 10px;
  /* Adjust the distance from the right side */
  transform: translateY(-50%);
  /* Center vertically */
}

.imageWidth {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

#documentModal .floating-control {
  width: auto;
  border: 2px solid var(--color-pri-blue);
  border-radius: 1px;
  padding: 8px 0;
}

#documentModal .floating-input {
  border: 0;
}

#documentModal .floating-label {
  background: var(--color-white);
  color: var(--color-pri-blue);
}

#documentModal .btn-upload {
  width: 208px;
  align-self: center;
}

#documentModal img {
  width: 70px;
}

#documentModal img.preview {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

#documentModal .icon-text {
  font-size: 13px;
  font-weight: bold;
}

#documentModal .icon-eye,
#documentModal .icon-close {
  font-size: 25px;
  cursor: pointer;
  color: var(--color-pri-blue);
}

#documentModal .icon-close {
  color: #ef3333;
}

#viewModal .icon {
  font-size: 25px;
  margin: 0 10px;
  cursor: pointer;
}

:is(#viewModal, #documentModal, #deleteModal) .close-icon {
  background: black;
  color: var(--color-white);
  font-size: 30px;
  margin: 0;
  padding: 7px;
  border-radius: 50%;
  z-index: 2;
  top: 0;
  right: 0;
  transform: translate(35%, -30%);
}

.w-min-content {
  max-width: 100% !important;
}

 /* #viewModal {
  background-color: rgba(0, 0, 0, 0.1);
}  */

#viewModal .modal-content {
  width: unset !important;
   min-width: 500px !important;
  margin: auto;
  overflow: unset !important;
}

#viewModal .modal-content .preview {
  display: block;
  object-fit: contain;
  max-width: 500px;
  min-height: 500px;
}

#pdf-controls {
  justify-content: center !important;
}

.notInherit {
  border-color: var(--bs-table-border-color);
}

input:focus {
  outline: none;
  /* Add any other styling you want for the focused state */
}

.floating-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*Added by Namrata*/
.multiSelectContainer ul {
  border: 2px solid var(--color-pri-blue) !important;
}

.videoUploadingStatus {
  top: 0;
  left: 0;
  color: white;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  font-size: 21px;
}

.underReview .instruction {
  border: 2px solid var(--color-pri-blue);
  width: min(750px, 90%);
  margin: 20px auto;
  padding: 60px;
  text-align: center;
}

.react-datepicker-popper {
  z-index: 2;
}

.deleteHighlight {
  background-color: hsl(0, 100%, 90%);
  color: #777;
}

@media (max-width: 1199.98px) {
  .w-lg-100 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .personalDetails .container {
    max-width: 320px;
  }

  #viewModal .modal-content {
    min-width: 320px !important;
  }

  #viewModal .modal-content .preview {
    max-width: 100%;
  }
  .btn-yourDocument {
    width:220px;
  }

  .mobile-view{
    display: block;
  }
  .desktop-view{
    display: none;
  }
  .countryDiv {
    width: 208px !important;
    margin-bottom: 20px;
  }

}

@media (max-width: 340px) {
  #viewModal .modal-content {
    min-width: 300px !important;
  }
}
 /* for testing */
 /* Base styling */
.data-card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.field {
  margin-bottom: 20px;
}


.field label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.field .form-check{
margin-left: -140px;
}

.sub-field {
  margin-bottom: 10px;
}

.sub-field label {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-bottom: 5px;
}
.yourDocSection{
  margin-left: 53px;
}


.SrNo{
  width: 30px;
  height: 30px;
  background-color: rgb(214, 51, 132);
  color: white;
  border-radius: 30px;
  text-align: center;

}

/* Testing */


.photoInfoContainer {
  display: grid;
  grid-template-columns:1fr,auto; /* Two equal-width columns */
  grid-template-rows: auto auto;
  gap: 10px;
}


.photoInfoContainer .item:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.photoInfoContainer .item:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.photoInfoContainer .item:nth-child(3) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

@media (max-width: 768px) {
  .photoInfoContainer {
      display: flex;
      flex-direction: column;
  }
  .photoInfoContainer .item {
      width: 100%;
  }
}













