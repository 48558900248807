
.rangeSlider .slider {
   height: 5px;
   position: relative;
   background: #ddd;
   border-radius: 5px;
}

.rangeSlider .slider .progress {
   height: 100%;
   left: 0%;
   right: 0%;
   position: absolute;
   border-radius: 5px;
   background: var(--color-black);
}

.rangeSlider .range-input {
   position: relative;
}

.rangeSlider .range-input input {
   position: absolute;
   width: 100%;
   height: 5px;
   top: -5px;
   background: none;
   border: none;
   pointer-events: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}
/* .rangeSlider .range-input input[type="range"][name="min"] {
   top:0;
}
.rangeSlider .range-input input[type="range"][name="max"] {
   top:-10px
} */

input[type="range"]::-webkit-slider-thumb {
   height: 17px;
   width: 17px;
   border: none;
   border-radius: 50%;
   background: var(--color-black);
   /* background: url('../../../public/images/blue-img.jpg'); */
   pointer-events: auto;
   -webkit-appearance: none;
   box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
/* 
input[type="range"][name="min"]::-webkit-slider-thumb{
   border: 0;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   border-bottom: 10px solid var(--color-black);

   border-top-left-radius: 10%;
   border-top-right-radius: 10%;
}
input[type="range"][name="max"]::-webkit-slider-thumb{
   border: 0;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   border-top: 10px solid var(--color-black);
   
   border-bottom-left-radius: 10%;
   border-bottom-right-radius: 10%;
} */

input[type="range"]::-moz-range-thumb {
   height: 17px;
   width: 17px;
   border: none;
   border-radius: 20%;
   background: var(--color-black);
   pointer-events: auto;
   -moz-appearance: none;
   box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
.changed-value{
   font-weight: 500;
   color: #918d8d;
}