.btn-browse-candidates {
  background-color: #005eab;
  color: #fff;
  font-size: 18px;
  border-radius: 20px;
  border: 0;
  outline: 0;
  padding: 5px 20px;
  transition: 0.3s all ease;
}

.btn-browse-candidates:hover {
  transform: scale(1.05);
  background-color: #005eab;
}

.box-shadow-bottom {
  box-shadow: 0px 15px 10px -16px rgba(0, 0, 0, 0.35);
}

.candidate_interview-list :is(.interview-on, .status) {
  white-space: nowrap;
}

.emp-home .interview-list tbody tr:is(:hover, .active) {
  box-shadow: inset 2px 2.1px 1px #005eab, inset -2px -2px 1px #005eab;
  -moz-box-shadow: inset 2px 3px 1px #005eab, inset -2px -2px #005eab;
}

.emp-home .interview-list td {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  vertical-align: middle;
}

/*code added by Namrata*/

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .responsiveTable td.pivoted {
    padding-left: 46% !important;
  }
}

@media (max-width: 640.98px) {
  .interview-list {
    border-radius: unset;
  }
}

@media (max-width: 575.98px) {
  .carousel-wrapper {
    width: 95vw;
    margin: auto;
  }
}
