.momDecl .instruction,.empInfo {
    border: 2px solid #005EAB;
    width: min(900px, 90%);
    margin: 20px auto;
    padding: 20px;
    text-align: center;
 }

 .otp-mom-wrapper input {
  border: none !important;
  outline: none !important;
  border-bottom: 3px solid #ccc !important;
  background-color: transparent;
  /* font-size: 20px; */
}

 .headingColor{
  color: #005EAB;
 }

 #NextBtn{
  background-color: #005EAB !important;
 }

 
  .momDecl .continueBtn
  {
    width: min(200px, 100%);
    background-color: #005EAB !important;
    border-radius: 30px !important;
    font-size: 18px !important;
    color: #fff !important;
  }

  .momDecl  .cancelBtn{
    width: min(200px, 100%);
    border-radius: 30px !important;
    font-size: 18px !important;
  }

  .momDecl .rdtPicker {
    margin-top: -200px !important;
    margin-left: -40px !important;
    background-color: #F5F5F5 !important;
    border: 1px solid black;
  }

 
  
  .momDecl  .toggle-container {
    display: flex;
    position: relative;
    width: 200px;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    cursor: pointer;
  }
  
  .momDecl  .option {
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    z-index: 1; /* Ensure buttons are above the slider */
    transition: color 0.3s ease;
  }
  
  .momDecl .option.active {
    color: white; /* Active button text color */
  }
  
  .momDecl  .slider {
    position: absolute;
    width: 50% ; /* Slider covers half the container for 2 options */
    height: 100%;
    background-color: #005EAB;
    border-radius: 25px;
    transition: all 0.3s ease; /* Smooth sliding effect */
    z-index: 0;
  }

  .empInfo .floating-label{
    background-color: white !important;
  }

  /* Remove blue border on focus */
.css-836teu-control {
  outline: none;
  box-shadow: none !important;
  border-color: #ccc !important; /* Optional: Customize border color */
}

/* Optional: Remove hover effect if needed */
.css-836teu-control:hover {
  border-color: #ccc !important;
}

/* Remove focus style for the dropdown menu */
.css-836teu-control {
  outline: none;
  box-shadow: none !important;
  border-color: #ccc !important; /* Optional: Customize border color */
}
  
  
