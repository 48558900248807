.NricInputBox__title {
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
  background-color: white;
  margin-top: -10px;
  margin-left: 12px;
  padding: 0 5px;
  transition: all 0.2s ease-in-out;
}

.NricInput__container {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 5px 20px;
}

.maskedText {
  color: #808080;
  font-size: 22px;
}

.dash {
  margin: 0 10px;
  color: #000;
}

.otp-4-input input {
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 2px solid #808080;
}

.otp-4-input input:last-child {
  margin: 0 !important;
}

@media (max-width: 390px) {
  .NricInput__container {
    padding-inline: 10px;
  }
  .otp-4-input input:not(:last-child) {
    margin-right: 5px !important;
  }
}
