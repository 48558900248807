.bnt-verify-wrapper label {
    width: min(168px, 100%);
    margin: 0 5px 10px;
 }
 .btn-verify{
    width: 100%;
 }
 .bnt-verify-wrapper label:hover {
    background-color: var(--color-pri-red);
 }
 #verifyByEmail,#verifyByMobile{
    display: none;
 }
 #verifyByEmail:checked + label[for=verifyByEmail]{
    background-color: var(--color-pri-red);
    color: var(--color-white);
 }  
 #verifyByMobile:checked + label[for=verifyByMobile]{
    background-color: var(--color-pri-red);
    color: var(--color-white);
 }
 .key-in-otp-wrapper :is(.key-in-otp,.otp-inp-wrapper){
    width: 178px;
 }