.btn-back {
  cursor: pointer;
}
.white-space-nowrap {
  white-space: nowrap;
}

.empOffer .interview-list .icon img {
  width: 30px;
}
.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

.empOffer .interview-list .icon .text {
  font-size: 9px;
}

.offer__action__container .offer__action__wrapper .icon {
   width: 93px;
}

.btn-makePayment {
  width: 153px;
}

.empOffer .interview-list .icon1 {
  color: rgb(39, 174, 96);
}

.empOffer .interview-list .icon2 {
  color: #007bff;
}

.empOffer .interview-list td {
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
}

.empOffer .reschedule-popup .form-control:focus {
  border: 1px solid var(--color-pri-blue);
  outline: 0;
  box-shadow: none; 
}

#offerFormModal .arrowDown {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  pointer-events: none;
  border-left: 1px solid var(--bs-gray-500);
  padding-left: 5px;
}

#offerFormModal select {
  appearance: none;
}

#offerFormModal .floating-label,.custom-modal .floating-label {
  background-color: white !important;
}

.w-106 {
  width: 106px !important;
}

.w-120 {
  width: 120px !important;
}

.w-160 {
  width: 160px !important;
}

.w-200 {
  width: 200px !important;
}

.sign-box {
  width: 150px;
  height: 100px;

  display: flex;
  align-items: center;
}

.sign-box1 {
  background-color: yellow;
}

.sign-box2 {
  background-color: #eee;
}

.sign-icon {
  width: 40px;
  object-fit: contain;
}

.sign-icon + span {
  font-size: 10px;
}

#offerPdfViewerModal .modal-content {
  width: unset !important;
  min-width: 500px !important;
  margin: auto;
  overflow: unset !important;
}

.empOffer input[type="radio"]:checked + label {
  background-color: #005eab;
  color: white;
}

.empOffer .interview-list tbody tr:is(:hover, .active) {
  box-shadow: inset 2px 2.1px 1px #005eab, inset -2px -2px 1px #005eab;
  -moz-box-shadow: inset 2px 3px 1px #005eab, inset -2px -2px 1px #005eab;
}

/* payment details css */
.btn-green {
  background-color: #27ae60 !important;
  border-radius: 20px !important;
  color: var(--color-white) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.paymentDetails-note {
  letter-spacing: 0.5px;
}

.paymentDetails-slip :is(.table, thead) {
  border: 1px solid black;
}

.paymentDetails-slip .table {
  width: 100%;
  border-collapse: collapse;
}

.paymentDetails-slip .table :is(th, td) {
  padding: 10px;
}

.paymentCardDetails-inputWrapper {
  width: min(450px, 100%);
  margin: auto;
}

.paymentSuccessfull-title {
  color: #27ae60;
}

.paymentSuccessfull-note {
  font-size: 18px;
}

/* payment details css End ^*/

/*datapicker style by Namrata*/

.react-datepicker__time-container {
  ::-webkit-scrollbar {
    height: 4px;
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(5, 3, 68, 0.5);
  }
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__navigation--previous {
  left: unset;
  right: 140px;
}

#makePaymentModal .ok {
  width: 100px;
  border-radius: 30px;
}
.floating-input:focus ~ .floating-label {
  color: black !important;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 767px) {
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__time-container {
    float: none;
    width: 100%;
    border-top: 1px solid #aeaeae;
    /*margin-bottom: 15px;*/
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    margin: 0px;
    width: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    display: flex;
    width: 300px;
    overflow: scroll;
    height: 45px !important;
    overflow-y: hidden;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 45px;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}
