.UploadImageAndTakePhoto__container {
  & .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  & .wrapper {
    max-width: 28rem;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 2rem;
  }

  & .btn-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }

  & .btn-wrapper button {
    background-color: var(--color-pri-red);
    color: white;
    border: none;
    padding: 1.5rem;
    border-radius: 10px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  & .btn-wrapper button svg {
    width: 3rem;
  }

  & .btn-wrapper button:hover {
    opacity: 0.9;
  }
  & .text-wrapper {
    color: #6b7280;
    text-align: center;
  }
}

.react-html5-camera-photo, .react-html5-camera-photo video {
  width: 100%;
}
