.webcamContainer {
  position: relative;
  width: 100%;
  padding: 25px;
}

.webcamVideo {
  width: 100%;
  min-height: 336px;
  border-radius: 10px;
  object-fit: cover;
}

.previewImg {
  width: 100%;
  min-height: 336px;
  border-radius: 10px;
  object-fit: cover;
}

.webcamCanvas {
  display: none; /* Hide canvas by default */
  transform: scaleX(-1);
}

.webcamButtonWrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.webcamButton {
  width: min(165px, 100%);
  background-color: #005eab;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}
.webcamButton.button-save {
  background-color: #238b45;
}
.webcamButton.button-cancel {
  background-color: #ccc;
  color: #333;
}

.webcamCaptureButton {
  position: absolute;
  bottom: 0px;

  width: 100%;
  background-color: #333;
  border-radius: 0 0 10px 10px;
  padding: 8px;

  display: flex;
  justify-content: center;
}

.captureButton {
  background-color: trasparent;
  border: none;
}
