.custom-modal {
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1000;
   opacity: 0;
   pointer-events: none;
   transition: all 0.3s ease-in-out;
}

.custom-modal.open {
   opacity: 1;
   pointer-events: auto;
}

.custom-modal-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.1);
}

.custom-modal-body {
   position: absolute;
   background-color: transparent;
   padding: 20px;
   border-radius: 5px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   width: 100%;
   height: 100%;
   z-index: 1;
}

.custom-modal h1 {
   font-size: 1.5rem;
   margin-bottom: 10px;
}

.custom-modal p {
   font-size: 1rem;
   line-height: 1.5;
}