/*.employee_sendVerificationCode .wrapper{
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
 }*/
 .employee_sendVerificationCode .message{
    font-size: 22px;
    color: var(--color-pri-blue);
 }
 .employee_sendVerificationCode button{
    width: min(100%, 150px);
 }
 