#imageViewer__modal {
  & .image__wrapper {
    aspect-ratio: 1/1;

    & img {
      height: 100%;
      object-fit: contain;
    }
  }
}
