.employee_profile-navbar {
   background-color: var(--color-white);
   box-shadow: 0px 15px 10px -16px rgba(0, 0, 0, 0.35);
}

.employee_profile-navbar .navbar-brand {
   font-size: 30px;
   font-weight: bold;
   font-family: 'Noto Sans JP', sans-serif;
}
#EmpNavBarLogOut{
   color: #005EAB;
   border-radius: 20px;
   width: 115px;
   margin-left: 10px;
}


.employee_profile-navbar .account_dropdown #collasible-nav-dropdown {
   color: var(--color-white);
}
.employee_profile-navbar .dropdown .name{
   display: inline-flex;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   vertical-align: middle;
}

.employee_profile-navbar .navbar-toggler:focus {
   box-shadow: 0 0 0 0.05rem;
}

.employee_profile-navbar .nav-link {
   letter-spacing: 1px
}

 .employee_profile-navbar .nav-link .nav-text {
    position: relative;
} 




 .employee_profile-navbar .nav-link .nav-text::before {
   content: '';
   position: absolute;
   bottom: -2px;
   width: 100%;
   height: 2px;
   background: var(--color-pri-blue);
   transform: scaleX(0);
   transition: transform .5s ease-in-out;
   transform-origin: right;
} 

.employee_profile-navbar .nav-link:hover .nav-text::before {
   transform: scaleX(1);
   transform-origin: left;
}


.employee_profile-navbar .nav-link.active .nav-text::before {
   transform: scaleX(1);
}

.hidden-navbar {
   visibility: hidden !important;
}



/*code added By Namrata new cosmetic changes*/
.employee_profile-navbar .nav-link {
   color: black;
}
.employee_profile-navbar .MuiBadge-badge{
   margin-right: -7px !important;
}

.employee_profile-navbar .nav-link:is(:focus, :hover) {
   color: #005EAB;
}

.employee_profile-navbar .navbar-toggler {
   /* border: 2px solid black; */
   background: #005EAB;
}

.employee_profile-navbar .navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
   color: #005EAB;
}

.employee_profile-navbar .Helperwishlogo {
   width: 50px;
   height: 40px;
}

.employee_profile-navbar .HelperwishlogoText {
   width: 200px;
}



.bell {
   font-size: 28px;
}

/* dropdown  */
.employee_profile-navbar .emp-dropdown .dropdown-content a:is(:hover, .active) {
   background-color: var(--color-pri-blue);
   color: var(--color-white);
}

/*Media query for Mobile*/
@media (max-width: 767px) {
   .hidden-navbar {
      display: none !important;
   }
   #EmpNavBarLogOut{
      width: 90px; ;
   }
}

