.loader-modal .modal-content {
  background-color: transparent;
  border: none;
}

.loader-wrapper {
  background-color: #fff;
  border-radius: 0.5rem;
  width: 150px;
  height: 150px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: -webkit-inline-grid;
  grid-template-columns: repeat(3, 30px);
  gap: 2px;
  scale: 0.5;
}

.loader-container span {
  display: inline-block;
  height: 30px;
  width: 30px;
  background: var(--color-pri-red);
  animation: blink 2s linear infinite;
}

.loader-container span:nth-child(5) {
  background: transparent;
  animation: none;
}

.loader-container span:nth-child(1) {
  animation-delay: 0.25s;
}

.loader-container span:nth-child(2) {
  animation-delay: 0.5s;
}

.loader-container span:nth-child(3) {
  animation-delay: 0.75s;
}

.loader-container span:nth-child(6) {
  animation-delay: 1s;
}

.loader-container span:nth-child(9) {
  animation-delay: 1.25s;
}

.loader-container span:nth-child(8) {
  animation-delay: 1.5s;
}

.loader-container span:nth-child(7) {
  animation-delay: 1.75s;
}

.loader-container span:nth-child(4) {
  animation-delay: 2s;
}

@keyframes blink {
  40%,
  60% {
    background: var(--color-pri-blue);
  }
  100% {
    background: var(--color-pri-blue);
  }
}
