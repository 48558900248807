.onboarding-list .table-responsive {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.onboarding-list .onboarding-list-table td {
  vertical-align: middle;
  word-wrap: break-word;
  white-space: normal;
}

.selectedCandidate-wrapper input[type="radio"]:checked + label {
  background-color: #f04da1;
  color: white;
}

.steps-wrapper .steps {
  margin: auto;
  padding: 15px;
  padding-left: 35px;

  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  position: relative;

  border: 1.5px solid var(--color-pri-blue);
  border-radius: 10px;
}

.steps-wrapper .step1 {
  background-color: #005eab21;
  margin-bottom: 15px;
}

.steps-wrapper .step2 {
  background-color: #ff7f5024;
}

.steps-wrapper .steps .srNo {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--color-pri-blue);
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 40px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.steps-wrapper .steps .title {
  font-weight: 500;
  font-size: 25px;
  width: 240px;
}





.steps-wrapper .steps .label {
  font-size: 13px;
  font-weight: 500;
  padding-inline: 5px;
  /* pd-left-right */
}

.arrowDown {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  /* pointer-events: none; */
  border-left: 1px solid var(--bs-gray-500);
  padding-left: 5px;
}

.steps-wrapper .steps .form-control:focus {
  border: 1px solid var(--color-pri-blue);
  box-shadow: unset !important;
}

.steps-wrapper .steps #cancellationReason {
  border: none !important;
}

.steps-wrapper .steps .rdtPicker {
  border: 1.5px solid var(--color-grey);
}

.steps-wrapper .steps .arrivalDateWrapper {
  padding: 6px 12px;
}

/* steps inactive css */
.steps-wrapper .steps.inactive > * {
  pointer-events: none;
}

.steps-wrapper .steps.inactive {
  background-color: #cccccc7a;
  border-color: #010101ca;
}

.steps-wrapper .steps.inactive :is(.srNo, .floating-control, select, .btn) {
  background-color: #ccc;
  color: #010101ca;
  border: 1px solid #010101ca;
}

.steps-wrapper .steps.inactive select {
  border: unset !important;
}

.steps-wrapper .steps.inactive .title {
  color: #8b8b8b;
}

/* candidate-details modal ------------------ */
#viewMoreDetailsModal .modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.close-icon {
  z-index: 1;
}

.candidate-details p {
  margin-bottom: 0;
}

.candidate-details :is(.description-1, .description-2) {
  padding: 10px 0;
}

.candidate-details .description-1 {
  background-color: #005eab;
  color: var(--color-white);
  font-size: 16px;
}

.candidate-details .description-1 .profile-wrapper {
  position: relative;
  width: 250px;
  text-align: center;
}

.candidate-details .description-1 .profile-wrapper img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--color-white);
  background-color: var(--color-white);

  /* position: absolute;
   left: 50%;
   bottom: 0;
   transform: translate(-50%, 50%); */
}

.candidate-details .description-1 .name {
  font-size: 27px;
  font-weight: 500;
  padding-top: 5px;
}

.candidate-details .description-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-size: 18px;
  font-weight: 500;
}

.candidate-details .description-2 span {
  color: #005eab;
}

.candidate-details :is(.workDetails, .skills) p {
  display: flex;
}

.candidate-details :is(.workDetails, .skills) {
  font-size: 18px;
  font-weight: 500;
}

.candidate-details .workDetails .table-responsive {
  font-size: initial;
}

.candidate-details :is(.workDetails, .skills) h3 {
  padding: 5px;
  background-color: #eee;
  border-bottom: 2px solid #005eab;
  font-size: 18px;
}

.candidate-details :is(.workDetails, .skills) p span:last-child {
  color: #005eab;
}

.workDetails .video-wrapper :is(img, video) {
  width: 90px;
  border-radius: 3px;
  display: block;
}

.workDetails .video-wrapper video {
  width: 130px;
}

.candidate-details .workDetails .eye-icon {
  color: #005eab;
}

.candidate-details .workDetails table tbody tr:nth-child(odd) {
  background: #ccc;
}

#viewMoreDetailsModal .close-icon,
#helperModal .close-icon {
  background: black;
  color: var(--color-white);
  font-size: 30px;
  margin: 0;
  padding: 7px;
  border-radius: 50%;
  z-index: 2;
  top: 0;
  right: 0;
  transform: translate(35%, -30%);
}

#viewMoreDetailsModal .modal-content {
  width: unset !important;
  /* min-width: 500px !important; */
  margin: auto;
  overflow: unset !important;
}

#helperModal .modal-content {
  width: unset !important;
  margin: auto;
  overflow: unset !important;
}

/* #helperModal.pdf .modal-content {
  min-width: 600px !important;
} */

.btn-zoom {
  width: 35px;
  height: 35px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  border: 0px;
  border-radius: 35px;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
}

.selectedCandidate-conatiner input[type="radio"]:checked + label {
  background-color: var(--color-pri-blue);
  border: 1px solid var(--color-pri-blue);
  color: white;
}

/* /responsive css/ */
@media (max-width: 1199.98px) {
  .steps-wrapper .steps {
    justify-content: center;
    margin-left: 22px;
  }
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .steps-wrapper .steps {
    padding: 10px;
    margin-inline: 10px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .steps-wrapper .steps .srNo {
    position: unset;
    transform: unset;
  }

  .steps-wrapper .steps .title {
    font-weight: 550;
    font-size: 21px;
  }

  .gap-sm-20 {
    gap: 20px !important;
  }

  .h-sm-60 {
    height: 60px;
  }
}

@media screen and (max-width: 40em) {
  .selectedCandidate-wrapper .responsiveTable tbody tr:nth-child(odd) {
    margin-bottom: 0;
    box-shadow: none;
  }

  .h-sm-40 {
    height: 40px;
  }
}
