.gap-50 {
  gap: 50px;
}

.CandidateCard {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.35);
}

.CandidateCard .card-body {
  padding-bottom: 15px;
}

.CandidateCard p {
  margin: 0;
}

.candidate-info {
  border: 1px solid var(--color-grey);
}

.border-3-blue {
  border: 3px solid var(--color-pri-blue);
}

.even-card {
  background: #005eab;
}

.odd-card {
  background: var(--color-pri-red);
}

.candidate-info .description-1 {
  color: #fff;
}

.candidate-info .profile-wrapper {
  width: 200px;
  text-align: center;
  position: relative;
}

.flex-grow-1 {
  flex-grow: 1;
  min-width: 0; /* Allows the text to wrap if needed */
}

.candidate-info .profile-wrapper img {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--color-white);
  background-color: var(--color-white);

  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.candidate-info .name {
  font-size: 22px;
  font-weight: 550;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.candidate-info .country {
  margin-left: -8px;
}

.candidate-info :is(.age, .availability, .country) {
  font-size: 18px;
  padding-bottom: 3px;
}

.candidate-info .country .location-icon {
  color: #14a562;
  font-size: 35px;
}

.nameInfo {
  margin-left: -28px;
}

.btn__wrapper {
  padding-top: 70px;
  width: 200px;
}

.btn-scheduleInterview,
.btn-viewMore {
  width: 160px;
  font-size: 15px;
  border-radius: 25px;
  padding: 6px 7px;
  margin: 0 10px;
  border: 0;
  outline: 0;
  transition: 0.3s all ease;
}

.btn-red-reshedule {
  background-color: #005eab;
  color: white;
}

:is(.btn-scheduleInterview, .btn-viewMore):hover {
  transform: scale(1.05);
  background-color: #005eab;
}

button[disabled],
button[disabled]:hover {
  background-color: #898585;
}

.description-2 {
  position: relative;
}

.description-2 .summary {
  font-size: 18px;
  font-weight: 500;
}

.description-2 .summary p {
  margin: 0;
}

.description-2 .summary p span {
  color: #005eab;
}

/* candidate-details modal ------------------ */
#viewMoreDetailsModal .modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.close-icon {
  z-index: 1;
}

.candidate-details p {
  margin-bottom: 0;
}

.candidate-details :is(.description-1, .description-2) {
  padding: 10px 0;
}

.candidate-details .description-1 {
  background-color: #005eab;
  color: var(--color-white);
  font-size: 22px;
}

.candidate-details .description-1 .profile-wrapper {
  position: relative;
  width: 250px;
  text-align: center;
}

.candidate-details .description-1 .profile-wrapper img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--color-white);
  background-color: var(--color-white);

  /* position: absolute;
   left: 50%;
   bottom: 0;
   transform: translate(-50%, 50%); */
}

.candidate-details .description-1 .name {
  font-size: 27px;
  font-weight: 500;
  padding-top: 5px;
}

.candidate-details .description-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-size: 18px;
  font-weight: 500;
}

.candidate-details .description-2 p span {
  color: #005eab;
}

.candidate-details :is(.workDetails, .skills) p {
  display: flex;
}

.candidate-details :is(.workDetails, .skills) {
  font-size: 18px;
  font-weight: 500;
}

.candidate-details .workDetails .table-responsive {
  font-size: initial;
}

.candidate-details :is(.workDetails, .skills) h3 {
  padding: 5px;
  background-color: #eee;
  border-bottom: 2px solid #005eab;
  font-size: 24px;
}

.candidate-details :is(.workDetails, .skills) p span:last-child {
  color: #005eab;
}

.workDetails .video-wrapper :is(img, video) {
  width: 90px;
  border-radius: 3px;
  display: block;
}

.workDetails .video-wrapper video {
  width: 130px;
}

.candidate-details .workDetails .eye-icon {
  color: #005eab;
}

.candidate-details .workDetails table tbody tr:nth-child(odd) {
  background: #ccc;
}

#helperModal {
  background-color: rgba(0, 0, 0, 0.1);
}

#viewMoreDetailsModal .close-icon,
#helperModal .close-icon {
  background: black;
  color: var(--color-white);
  font-size: 30px;
  margin: 0;
  padding: 7px;
  border-radius: 50%;
  z-index: 2;
  top: 0;
  right: 0;
  transform: translate(35%, -30%);
}

#viewMoreDetailsModal .modal-content {
  width: unset !important;
  /* min-width: 500px !important; */
  margin: auto;
  overflow: unset !important;
}

#helperModal .modal-content {
  width: unset !important;
  margin: auto;
  overflow: unset !important;
}

#helperModal.pdf .modal-content {
  min-width: 600px !important;
}

.btn-zoom {
  width: 35px;
  height: 35px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  border: 0px;
  border-radius: 35px;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
}

@media (max-width: 991.98px) {
  .candidate-details .description-1 .profile-wrapper {
    width: 100%;
  }

  .candidate-details .description-2 {
    padding-left: 0;
  }

  .candidate-info .name {
    font-size: 21px;
  }

  .btn-scheduleInterview,
  .btn-viewMore {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .CandidateCard {
    margin-top: 80px;
  }

  .candidate-info .description-1 {
    padding-bottom: 10px;
  }

  .candidate-info .description-1 p {
    margin-bottom: 0;
  }

  .candidate-info .name {
    padding-top: 65px;
    margin: 0 auto;
  }

  .candidate-info .profile-wrapper img {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    /* box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.35); */
  }

  .candidate-info .profile-wrapper {
    margin: auto;
  }

  .btn__wrapper {
    width: auto;
    padding-top: 20px;
  }

  .btn-viewMore {
    position: unset;
  }

  #helperModal.pdf .modal-content {
    min-width: 575px !important;
  }

  .description-2 .summary p {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .candidate-info .name {
    font-size: 20px;
  }

  #helperModal.pdf .modal-content {
    min-width: 491px !important;
  }
}

@media (max-width: 491.98px) {
  .candidateCardViewMoreDetailsModal .candidate-details .description-2 {
    grid-template-columns: 1fr;
    text-align: center;
  }

  #helperModal.pdf .modal-content {
    min-width: 320px !important;
  }
}

@media screen and (max-width: 40em) {
  .candidate-details .responsiveTable td .tdBefore {
    width: 50%;
  }

  .workDetails .tbl-td {
    width: auto;
  }
}

@media (max-width: 445.98px) {
  .candidate-details :is(.workDetails) p {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__navigation--previous {
  left: unset;
  right: 140px;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 767px) {
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__time-container {
    float: none;
    width: 100%;
    border-top: 1px solid #aeaeae;
    /* margin-bottom: 15px;*/
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    margin: 0px;
    width: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    display: flex;
    width: 300px;
    overflow: scroll;
    height: 45px !important;
    overflow-y: hidden;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 45px;
  }
}
