/* .resetpassword-wrapper{
   width: 100%;
   height: 100%;
}
.resetpassword-form-wrapper{
   width: min(400px,100%);
   margin: 0 auto;
   background-color: var(--color-light);
   box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
   
} */
.floating-control:has(#password, #confirmPassword) {
   border-radius: 0.25rem 0 0 0.25rem;
}

.resetpassword-wrapper .input-group-text {
   border-radius: 0 .375rem .375rem 0;
}

.candidate__resetPassword__success {
   text-align: center;
}

.candidate__resetPassword__success p {
   color: var(--color-pri-blue);
   font-size: 20px;
   font-weight: 500;
}

.candidate__resetPassword__success button {
   width: min(200px, 100%);
}