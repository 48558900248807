.container {
   position: relative;
   width: 20em;
   min-height: 1.5em;
   border: .05em solid #777;
   display: flex;
   align-items: center;
   gap: .5em;
   padding: .5em;
   border-radius: .25em;
   outline: none;
}

.container:focus {
   border-color: hsl(200, 100%, 50%);
}

.value {
   flex-grow: 1;
   display: flex;
   gap: .5em;
   flex-wrap: wrap;
}

.placeholder {
   display: inline-flex;
   align-items: center;
   color: #777;
}

.clear-btn {
   background: none;
   color: #777;
   border: none;
   outline: none;
   cursor: pointer;
   padding: 0;
   font-size: 1.25em;
}

.clear-btn:focus,
.clear-btn:hover {
   color: #333;
}

.divider {
   background-color: #777;
   align-self: stretch;
   width: .05em;
}

.caret {
   translate: 0 25%;
   border: .25em solid transparent;
   border-top-color: #777;
}

.options {
   position: absolute;
   margin: 0;
   padding: 0;
   list-style: none;
   display: none;
   max-height: 15em;
   overflow-y: auto;
   border: .05em solid #777;
   border-radius: .25em;
   width: 100%;
   left: 0;
   top: calc(100% + .25em);
   background-color: white;
   z-index: 100;
}

.options.show {
   display: block;
}

.option {
   padding: .25em .5em;
   cursor: pointer;
}

.option.selected {
   /* background-color: hsl(200, 100%, 70%); */
   color: #777;
}

.option.highlighted {
   background-color: hsl(200, 100%, 50%);
   color: white
}

.option-badge {
   display: inline-flex;
   align-items: center;
   border: .05em solid #777;
   border-radius: .25em;
   padding: .15em .25em;
   gap: .25em;
   background: var(--color-pri-blue);
   color: var(--color-white);
   outline: none;
   font-size: 13px;
}

.option-badge:hover,
.option-badge:focus {
   /* background-color: hsl(0, 100%, 90%);
   border-color: hsl(0, 100%, 50%);
   color: #777; */
   background-color: var(--color-pri-blue) !important;
   border-color: #777 !important;
   color: var(--color-white) !important;
}

.option-badge:hover>.remove-btn,
.option-badge:focus>.remove-btn {
   color: var(--color-white) !important;
}

.option-badge>.remove-btn {
   font-size: 1.25em;
   color: #777;
   cursor: pointer;
   color: var(--color-white);
}