.doument__preview {
  width: 50px;
}
.preview{
  width: 100%;
  height: 50px;
}
.file__name{
  font-size: 12px;
}
.text-icon-view{
  font-size: 14px;
}
.icon-view{
  font-size: 25px;
}