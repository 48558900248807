.record-wrapper{
   min-width: 130px;
   max-width: 200px;
}
.content .card {
   width: min(300px, 100%);
   background-color: #0d6efd;
   color: var(--color-white);
   font-size: 20px;
   margin-bottom: 10px;
}

.icon {
   display: flex;
   flex-direction: column;
   align-items: center;
   cursor: pointer;
}


:is(#detailsModal) .close-icon {
   background: var(--color-black);
   color: var(--color-white);
   font-size: 30px;
   margin: 0;
   padding: 7px;
   border-radius: 50%;
   z-index: 2;
   top: 0;
   right: 0;
   transform: translate(35%, -30%);
}

#detailsModal .profile_photo-img {
   width: 120px;
   height: 120px;
   border: 1px solid var(--color-pri-blue);
   border-radius: 50%;
   object-fit: cover;
}

#detailsModal .full_photo-img {
   width: 120px;
   min-height: 120px;
   border: 1px solid var(--color-pri-blue);
   border-radius: 5px;
}

#detailsModal .personal-info {
   font-size: 20px;
   font-weight: 500;
}

#detailsModal .personal-info div span:nth-child(2) {
   font-weight: 350;
   margin-left: 8px;
}

:is(.content, #detailsModal) thead {
   background-color: #0d6efd;
   color: var(--color-white);
}

.your-document .preview {
   width: 60px;
   height: 60px;
   object-fit: cover;
}

.your-document .floating-control {
   width: auto;
}

#documentModel,
.modal {
   background-color: rgba(0, 0, 0, 0);
}


:is(#approveConfirmModal, #messageModal) p {
   font-size: 22px;
   font-weight: 450;
}

#approveConfirmModal button {
   width: 150px;
}

#approveConfirmModal .modal-content {
   overflow: unset !important;
}

:is(#approveConfirmModal) .close-icon {
   background: var(--color-black);
   color: var(--color-white);
   font-size: 30px;
   margin: 0;
   padding: 7px;
   border-radius: 50%;
   z-index: 2;
   top: 0;
   right: 0;
   transform: translate(35%, -30%);
   cursor: pointer;
}