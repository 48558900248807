.form-login .btn-loginEmp {
   width: 50%;
}

.forgotPasswordText {
   cursor: pointer;
}

.form-login #password {
   border-radius: 0.25rem 0 0 0.25rem
}

.form-login .input-group-text {
   border-radius: 0 .375rem .375rem 0;
}

@media (max-width: 575.98px) {
   .form-login .btn-loginEmp {
      width: 100%;
   }
}