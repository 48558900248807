.candidates-list .slider-container:nth-child(odd) .slider-wrapper .slider-card .name {
   background: #f7053e;
}

.candidates-list .slider-container:nth-child(even) .slider-wrapper .slider-card .name {
   background: var(--color-pri-blue);
}

.emp-browse-candidates {
   position: relative;
}

.emp-browse-candidates .text-red {
   color: #005eab;
}

/* .browse-candidate-wrapper {
   height: calc(100vh - 120px);
   overflow: auto;
} */

.btn-filter {
   margin: auto;
   padding: 5px 10px;
   border: 1px solid var(--bs-gray-500);
   outline: 0;
   border-radius: 5px;
   box-shadow: rgba(0, 0, 0, 0.35) 0 0 4px;
   /* 
   position: absolute;
   top: 10px;
   z-index: 1;
    */
   display: none;
}

.closeFilter {
   position: relative;
   display: none;
}

.closeFilter .close {
   background: white;
   border-radius: 50%;
   font-size: 33px;
   position: absolute;
   right: -16px;
   top: -16px;
   cursor: pointer;
}

.btn-filter img {
   width: 40px;
   height: 40px;
}

.candidate-filter {
   min-width: 280px;
}

.candidate-filter-wrapper {
   border: none;
   border-radius: 20px
}

.filter-container {
   color: #005EAB;
}

.filter {
   box-shadow: 0px 15px 10px -16px rgba(0, 0, 0, 0.35);
}

.filter-container .filter-icon {
   font-size: 25px;
}

.f-20 {
   font-size: 20px;
}

.f-25 {
   font-size: 25px;
}

.fs-13 {
   font-size: 13px;
}

.filterMultiSelect-container {
   width: 100% !important;
}

.multiselect .search-wrapper {
   border: 2px solid #ccc;
}

.multiselect .chip {
   background-color: black;
}

.multiselect #search_input {
   width: 100px;
}

.multiselect .arrowDown {
   position: absolute;
   right: 4px;
   top: 50%;
   transform: translateY(-50%);
   font-size: 20px;
   pointer-events: none;
   border-left: 1px solid var(--bs-gray-500);
   padding-left: 5px;
}


@media (max-width: 1199.98px) {

   .btn-filter,
   .closeFilter {
      display: block;
   }

   .candidate-filter {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--color-white);
      z-index: 1;
      display: none;
      border-radius: 20px;
      box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.35);
   }

}