/* .resetpassword-wrapper{
   width: 100%;
   height: 100%;
}
.resetpassword-form-wrapper{
   width: min(400px,100%);
   margin: 0 auto;
   background-color: var(--color-light);
   box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
   
} */
.employee__resetPassword__success{
   text-align: center;
}
.employee__resetPassword__success p {
   color: var(--color-pri-red);
   font-size: 20px;
   font-weight: 500;
}
.employee__resetPassword__success button {
   width: min(200px,100%);
}