.Admin-Login {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--color-white);
}

.Admin-Login input {
   color: var(--color-white);
}

.Admin-Login .form-wrapper {
   width: min(650px, 100%);
   background: var(--color-pri-blue);
   display: flex;
   justify-content: center;
   align-items: center;
}

.Admin-Login .form-login {
   width: min(455px, 100%);
}

.Admin-Login .floating-label {
   background: var(--color-pri-blue);
   color: var(--color-white);
}

.Admin-Login .floating-input:focus {
   outline: none;
   border: 2px solid var(--color-white) !important;
}


.Admin-Login .floating-input:focus~.floating-label {
   color: var(--color-white);
}

.forgotPasswordText {
   cursor: pointer;
}

@media (max-width: 575.98px) {
   .form-login .btn-login {
      width: 100%;
   }
}