/* navbar css start  */
.candidate_profile-navbar {
   background-color: var(--color-white);
   box-shadow: 0px 15px 10px -16px rgba(0, 0, 0, 0.35) !important;
   margin-bottom: 5px;
}

.candidate_profile-navbar .navbar-brand {
   font-size: 30px;
   font-weight: bold;
   font-family: 'Noto Sans JP', sans-serif;
}

#NavBarLogOut{
   color: #F04DA1;
   border-radius: 20px;
   width: 115px;
}

.candidate_profile-navbar .account_dropdown #collasible-nav-dropdown {
   color: var(--color-white);
}

.candidate_profile-navbar .account_dropdown .dropdown-menu {
   left: -100%;
}

.candidate_profile-navbar .account_dropdown:hover .dropdown-menu {
   display: block;
}

.candidate_profile-navbar .navbar-toggler:focus {
   box-shadow: 0 0 0 0.05rem;
}

.candidate_profile-navbar .nav-link {
   letter-spacing: 1px
}

.candidate_profile-navbar .nav-link .nav-text {
   position: relative;
}

.candidate_profile-navbar .nav-link .nav-text::before {
   content: '';
   position: absolute;
   bottom: -2px;
   width: 100%;
   height: 2px;
   background: var(--color-pri-red);
   transform: scaleX(0);
   transition: transform .5s ease-in-out;
   transform-origin: right;
}


.candidate_profile-navbar .nav-link:hover .nav-text,
.candidate_profile-navbar .nav-link.active .nav-text {
   color: var(--color-pri-red);
}

.candidate_profile-navbar .nav-link:hover .nav-text::before {
   transform: scaleX(1);
   transform-origin: left;
}

.candidate_profile-navbar .MuiBadge-badge{
   margin-right: -7px;
   background-color: var(--color-pri-red) !important;
   color: white !important;
}

.candidate_profile-navbar .nav-link.active .nav-text::before {
   transform: scaleX(1);
} 

.candidate_profile-navbar .dropdown .name{
   display: inline-flex;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   vertical-align: middle;
}

.mini-profile-photo {
   width: 40px;
   height: 40px;
   border: 1px solid var(--color-white);
   background-color: var(--color-white);
   border-radius: 50%;
   object-fit: cover;
}

/* navbar css end  */

/* Dropdown Button */
.dropbtn {
   background-color: transparent;
   padding: 16px;
   font-size: 16px;
   border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
   position: relative;
   display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
   display: none;
   position: absolute;
   top: 86%;
   right: 0;
   background-color: #f1f1f1;
   min-width: 160px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
   z-index: 2;
   border-radius: 5px;
}

.dropdown-content a:first-child {
   border-radius: 5px 5px 0 0;
}

.dropdown-content a:last-child {
   border-radius: 0 0 5px 5px;
}

.dropdown-content::before {
   position: absolute;
   right: 25px;
   transform: translateY(-100%);
   display: inline-block;
   content: "";
   border-top: 0;
   border-right: 10px solid transparent;
   border-bottom: 15px solid white;
   border-left: 10px solid transparent;
}

/* Links inside the dropdown */
.dropdown-content a {
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
}

/* Change color of dropdown links on hover and active */
.dropdown-content a:is(:hover, .active) {
   background-color: var(--bs-blue);
   color: var(--color-white);
}

.candidate_profile-navbar .cnd-dropdown .dropdown-content a:is(:hover, .active) {
   background-color: var(--color-pri-red);
}

/* Show the dropdown menu on hover */
.mini-profile-wrapper:hover+.dropdown .dropdown-content,
.dropdown:hover .dropdown-content {
   display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
   background-color: transparent;
}

.hidden-navbar {
   visibility: hidden !important;
}

/* Added Code by Namrata*/
.candidate_profile-navbar .Helperwishlogo {
   width: 50px;
   height: 40px;
}

.candidate_profile-navbar .HelperwishlogoText {
   width: 200px;
}

.candidate_profile-navbar .nav-link {
   color: black !important;
}

.candidate_profile-navbar .nav-link:focus,
.employee_profile-navbar .nav-link:hover {
   color: #F04DA1;
}

.candidate_profile-navbar .navbar-toggler {
   /* border: 2px solid black; */
   background: #F04DA1;
}

.candidate_profile-navbar .navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
   color: #F04DA1;
}



@media (max-width: 576px) {
   .hidden-navbar {
      display: none !important;
   }
   #NavBarLogOut{
      width: 90px; ;
   }
}


@media (max-width: 991.98px) {
   .dropbtn {
      padding-left: 0;
   }

   .dropdown-content {
      position: unset;
   }

   .dropdown-content::before {
      right: auto;
      left: 10px;
   }
}