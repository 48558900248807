.candidate_account .sub-title {
   padding-bottom: 10px;
   border-bottom: 1px solid var(--color-grey);
}

:is(.candidate_account, #changeProfileModal) .account-photo {
   width: 200px;
   height: 200px;
}

:is(.candidate_account, #changeProfileModal) .account-photo img {
   border: 1px solid var(--color-pri-blue);
   border-radius: 50%;
   object-fit: cover;
}

.candidate_account .floating-label {
   background-color: var(--color-white) !important;
}

#changeNameModal .floating-label {
   background-color: var(--color-white) !important;
}
.phoneNumber-box .phoneNumber {
   flex-grow: 1;
}

.candidate_account .change-text {
   font-weight: 600;
   color: #0d6efd;
   text-decoration: underline;
   cursor: pointer;
}

.candidate_account .floating-input {
   color: var(--color-grey);
}

#accountModal .floating-label {
   background-color: var(--color-white);
}

#accountModal button {
   width: min(100%, 150px);
}

.close-icon {
   background: var(--color-black);
   color: var(--color-white);
   font-size: 30px;
   margin: 0;
   padding: 7px;
   border-radius: 50%;
   z-index: 2;
   top: 0;
   right: 0;
   transform: translate(35%, -30%);
}

.ConfirmSend .message {
   font-size: 22px;
   color: var(--color-pri-red);
}

:is(#changeProfileModal, #changeNameModal) button {
   width: min(100%, 150px);
}

.candidate_account .floating-control:has(.floating-input:focus) {
   border: 1px solid var(--color-grey);
}


.candidate_account .floating-input:focus~.floating-label {
   color: var(--color-black);
  
}
