.react-multi-carousel-track {
  margin: auto !important;
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

.slider-container {
  position: relative;
  padding: 20px 10px;
}

.slider-wrapper .slider-card {
  border: 1.6px solid var(--color-grey);
  box-shadow: rgba(0, 0, 0, 0.35) 4px 4px 8px;
  max-width: 365px;
  /* position: relative; */
  margin: auto;
}

.slider-wrapper .slider-card .card__head {
  background: #005eab;
  display: flex;
  height: 100px;
}

.react-multi-carousel-track
  li:nth-child(even)
  .slider-wrapper
  .slider-card
  .card__head {
  background: var(--color-pri-red);
}

.slider-wrapper .slider-card .card__head .profile__wrapper {
  flex-basis: 120px;
  position: relative;
}

.slider-wrapper .slider-card .profile-img {
  width: 80px;
  height: 80px;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 3px solid var(--color-white);
  object-fit: cover;
  position: absolute;
  /* bottom: 0; */
  left: 50%;
  transform: translate(-50%, 15%);
}

.slider-wrapper .slider-card .name {
  width: 200px;
  color: #fff;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: 450;
}

.slider-wrapper .slider-card .name span {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Allow up to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  max-width: 20ch; /* Limits to 20 characters */
}

.slider-wrapper .slider-card .summary {
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
}

.summary p {
  margin: 0px;
}

.slider-wrapper .slider-card .country {
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.slider-wrapper .slider-card .country .icon {
  color: #14a562;
  font-size: 35px;
}

@media (max-width: 575.98px) {
  .slider-container {
    padding-top: 60px;
  }

  .slider-wrapper .slider-card .card__head {
    flex-direction: column;
    height: 120px;
    justify-content: center;
    padding-left: 50px;
   
  }

  .slider-wrapper .slider-card .card__head .profile__wrapper {
    flex-basis: 40px;
  }

  .slider-wrapper .slider-card .profile-img {
    top: 0;
    left: 40%;
    transform: translate(-50%, -50%);
  }

  .slider-wrapper .slider-card .name {
    width: auto;
    padding-inline: 0;
  }

  .slider-wrapper .slider-card .summary {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .slider-wrapper .slider-card .summary :is(.age, .availability) {
    flex-grow: 1;
    white-space: nowrap;
  }
  .slider-wrapper .slider-card .summary .availability {
    /* text-align: end; */
  }

  .slider-wrapper .slider-card .country {
    flex-basis: 100%;
    justify-content: center;
  }
}
