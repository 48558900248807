.desktop-table-container .name {
  min-width: 260px;
  max-width: 300px;
}
.mobile-table-container .table__card {
  border-radius: 10px;
  background-color: #eff2f4;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
}
.mobile-table-container .table__card .table__card__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
}

@media (max-width: 768.98px) {
  .desktop-table-container {
    display: none;
  }
}

@media (max-width: 500.98px) {
  .mobile-table-container .table__card .table__card__row {
    display: block;
  }
  .mobile-table-container .table__card > *:first-child {
    display: flex;
  }

  .mobile-table-container .table__card .table__card__row .profile-picture {
    margin: auto;
    margin-left: calc(50% - 50px);
  }

  .mobile-table-container .table__card .table__card__row :is(.label, .field) {
    font-size: 18px;
  }

  .mobile-table-container .name {
    min-width: 300px;
  }
}
