.card1 {
  --color: #00b24f;
}
.card2 {
  --color: #0071c1;
}
.card3 {
  --color: #c14e0d;
}
.card4 {
  --color: #791a6e;
}
.card5 {
  --color: #212121;
}
.card6 {
  --color: #0e3c64;
}

.card-container p {
  margin: 0 !important;
}

.card-container main {
  margin: auto;
}

.card-container {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.35);
  transition: all 0.3s;
}

.card-container:hover {
  transform: scale(1.02);
}

.card-container .card__left {
  flex: 0 0 200px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container .card__left .sr_No {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.card-container .card__left {
  background-color: var(--color);
}

.card-container .card__right {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
}

.card-container .card__right .card__title {
  color: var(--color);
  font-weight: 600;
  font-size: 18px;
}

.card-container .card__right li:not(:last-child) {
  margin-bottom: 5px;
}

.card-container .card__right .card__data span {
  color: var(--color);
}

@media (max-width: 991.98px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }
  .card-container .card__left {
    flex: 0 0 100px;
  }
}

/* // on Arrival cards css  */

.guideForOnArrival .card1 {
  --color: #4d96da;
}

.guideForOnArrival .card2 {
  --color: #e69fde;
}

.guideForOnArrival .card-container .card__left .sr_No {
  color: #000;
}
