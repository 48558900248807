
.verifyCode .message {
   font-size: 17px;
   color: var(--color-pri-red);
}

.verifyCode .otp-wrapper input {
   border: none;
   outline: none;
   border-bottom: 3px solid #ccc;
   background-color: transparent;
}

.verifyCode .resend-msg {
   font-size: 18px;
}

.verifyCode .resend-span button {
   border: none;
   outline: none;
   color: var(--color-pri-red);
   background: transparent;
}

.verifyCode .btn-verify {
   width: min(100%, 320px);
}

@media (max-width: 575.98px) {
   .otp-wrapper input {
      margin-right: 10px !important;
   }
}