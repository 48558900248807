/* Home page css ------------------------------*/
.Home,
.container,
.login-btn-wrapper {
  height: 100%;
}

.login-btn-wrapper .btn {
  color: var(--color-white);
  width: min(280px, 100%);
  height: 100px;
  font-size: 25px;
  border-radius: 15px;
}
