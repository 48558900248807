/* .candidate_sendVerificationCode .wrapper{
   border: 1px solid var(--color-black);
   background-color: var(--color-white); 
} */
.candidate_sendVerificationCode .message{
   font-size: 22px;
   color: var(--color-pri-red);
}
.candidate_sendVerificationCode button{
   width: min(100%, 150px);
}
