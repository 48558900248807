.emp-changePassword hr {
   border-bottom: 1px solid var(--color-grey);
}

.emp-changePassword .floating-label {
   background-color: var(--color-white);
}

.emp-changePassword button {
   width: min(100%, 250px);
}

.emp-changePassword .floating-input {
   border-radius: 0.25rem 0 0 0.25rem
}

.emp-changePassword .input-group-text {
   border-radius: 0 .375rem .375rem 0;
}