.air-datepicker {
  z-index: 9999 !important; /* Ensure it's above the modal */
}

.air-datepicker-time--current-hours,
.air-datepicker-time--current-minutes {
  font-size: 30px !important;
  font-weight: 400;
  color: black;
}

.air-datepicker-time--current-ampm {
  font-size: 16px !important;
  font-weight: 400;
  color: black;
}

.air-datepicker-nav,
.air-datepicker--time {
  min-height: 40px !important;
  /* border-bottom: 1px solid grey; */
}

.air-datepicker-time--row {
  height: 24px;
}

.air-datepicker--buttons {
  height: 68px;
  padding: 15px;
}

.air-datepicker-buttons {
  gap: 20px;
}

.air-datepicker-cell.-disabled- {
  color: #d0d0d0 !important;
}

.air-datepicker-nav--title {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: black;
}
.air-datepicker-nav--title i {
  color: black;
}

@media (max-width: 767px) {
  .air-datepicker-time--row {
    height: 35px;
  }
}
