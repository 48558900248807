.adminLayout a,
.adminLayout a:hover {
   text-decoration: none;
   color: var(--color-white);
}

.adminLayout .sidebar {
   width: 222px;
}

.adminLayout .list-group .list-item {
   cursor: pointer;
   display: flex;
   align-items: center;
}

.adminLayout .list-group .list-item span {
   position: relative;
}

.adminLayout .list-group .list-item span::before {
   content: '';
   position: absolute;
   bottom: -2px;
   width: 100%;
   height: 2px;
   background: var(--color-white);
   transform: scaleX(0);
   transition: transform .5s ease-in-out;
   transform-origin: right;
}

.adminLayout .list-group .list-item:hover span::before {
   transform: scaleX(1);
   transform-origin: left;
}

.adminLayout .list-group .list-item .list-link.active span::before {
   transform: scaleX(1);
}

.open-sidebar,
.close-sidebar {
   display: none;
}

@media (max-width: 991.98px) {

   .open-sidebar,
   .close-sidebar {
      display: inline-block;
   }

   .adminLayout .sidebar {
      position: absolute;
      position: absolute;
      top: 0;
      left: 0%;
      z-index: 2;
      display: none;
      height: 100%;
   }

   .adminLayout .sidebar.active {
      display: block;
   }
}


@media (max-width: 425.98px) {
   .adminLayout .sidebar {
      width: 100%;
   }

   .adminLayout .list-group .list-item {
      justify-content: center;
   }
}