/* .video-container video {
    transform: scaleX(-1);
} */
.close-icon-blue {
    background-color: black;
}

.video-container .live-player .countDown {
    width: 100px;

    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    font-size: 20px;
    font-weight: 500;
    background-color: #a6a6a6;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* .video-container .video-player{
    background-color: #e7e6e6;
} */
.video-container .btn-video__upload {
    height: 40px;
}

.video-container .video-controls {
    background-color: #ffe699;
}

.video-container .video-controls .icons {
    font-size: 30px;
}

.video-container .video-controls :is(.icon-start, .icon-stop) {
    border: 3px solid #fff;
    border-radius: 50%;
    padding: 2px;
}

.video-card-wrapper,
.single-card {
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
}

.video-card-wrapper {
    perspective: 900px;
}

.single-card {
    text-align: center;
    transition: all 1.5s cubic-bezier(.7, -.5, .3, 1.8);
    transform-style: preserve-3d;
}

.video-card-wrapper.flip .single-card {
    transform: rotateY(180deg);
}

.front,
.back {
    width: 100%;
    height: 100%;
    cursor: pointer;
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
}