.empAgreement .instruction {
  border: 2px solid #005eab;
  width: min(600px, 90%);
  margin: 20px auto;
  padding: 40px;
  text-align: center;
}

.empAgreement .proceedToC {
  background-color: #005eab;
  color: white;
}

.empAgreement .instruction p {
  font-size: 30px;
}

.btn-rounded {
  border-radius: 25px;
  padding: 10px 18px;
  margin: 0 35px;
  border: 0;
  outline: 0;
  transition: 0.3s all ease;
}

.btn-rounded:hover {
  transform: scale(1.05);
}

/* /----------------------------------------------------------------------/ */
.empAgreementSign .contractAgreement {
  width: min(800px, 90%);
  margin: 20px auto;
  border: 2px solid #005eab;
  padding: 20px 10px;
}

.empAgreementSign .agreement-text ul li {
  display: flex;
}

.empAgreementSign .agreement-text ul li span:first-child {
  margin-right: 5px;
}

.empAgreementSign :is(.btn-wrapper) .btn {
  min-width: 150px;
}

#agreementModal .btn {
  width: 150px;
}

#agreementModal .modal-content {
  border: 2px solid #005eab;
}

#agreementModal .floating-input {
  border: none;
}
#agreementModal .floating-control {
  border: 2px solid #005eab;
}

@media (max-width: 768.98px) {
  .empAgreementSign .contractAgreement .address {
    font-size: 15px;
  }
}
