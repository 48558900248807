
.candidate-changePassword hr{
   border-bottom: 1px solid var(--color-grey);
}
.candidate-changePassword .floating-label{
   background-color: var(--color-white);
}
.candidate-changePassword button{
   width: min(100%, 250px);
}
