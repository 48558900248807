
.EmpverifyCode .message {
   font-size: 17px;
}

.EmpverifyCode .otp-wrapper input {
   border: none;
   outline: none;
   border-bottom: 3px solid #ccc;
   background-color: transparent;
  /* font-size: 20px;*/
}

.EmpverifyCode .resend-msg {
   font-size: 18px;
}

.EmpverifyCode .resend-span button {
   border: none;
   outline: none;
   color: var(--color-pri-blue);
   background: transparent;
}

.EmpverifyCode .btn-verify {
   width: min(100%, 320px);
}