.modal:has(#pdfViewer__modal) ,.modal:has(#signaturePadModal){
  background: rgba(0, 0, 0, 0.3);
}

#pdfViewer__modal {
  & .pdfWrapper {
    min-height: 80vh;
    position: relative;
  }

  & .page-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767.98px) {
  #pdfViewer__modal {
    & .pdfWrapper {
      min-height: 70vh;
    }
  }
}
