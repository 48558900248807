/*navbar css start ---------*/
.navbar-brand span {
   font-size: 30px;
   font-weight: bold;
   font-family: 'Noto Sans JP', sans-serif;
}

.navbar-brand :first-child {
   color: #002D72;
}

.navbar-brand :nth-child(2) {
   color: #D50032;
}

.navbar-toggler-icon {
   width: 1em !important;
   height: 1em !important;
}
   
.Helperwishlogo {
   width: 50px;
   height: 40px;
}

.HelperwishlogoText {
   width: 200px;
}

/*navbar css start ---------*/