/*authentication layout css */
.authentication-wrapper {
   background-color: var(--color-pri-red);
   width: min(1000px, 100%);
   height: 300px;
   margin-inline: auto;
}

.Empauthentication-wrapper {
   background-color: var(--color-pri-blue);
   width: min(1000px, 100%);
   height: 300px;
   margin-inline: auto;
}

.authentication-info {
   position: relative;
}

.white-panel {
   background-color: var(--color-light);
   width: 50%;
   min-height: 400px;
   position: absolute;
   top: 50%;
   bottom: unset;
   transform: translate(0, -50%);
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   transition: all .2s linear;
}

.info {
   color: #f1f1f1;
   font-size: 20px;
   width: 50%;
}

:is(.login-info, .register-info) button {
   width: 50%;
}

.white-panel.register-active {
   transform: translate(100%, -50%);
}

.show-login.btn-active,
.show-register.btn-active {
   color: var(--bs-btn-hover-color);
   background-color: var(--bs-btn-hover-bg);
   border-color: var(--bs-btn-hover-border-color);
}

@media (max-width: 991.98px) {

   .authentication-wrapper,
   .Empauthentication-wrapper {
      margin: 100px auto;
   }

   .info {
      width: 100%;
   }

   .authentication-wrapper,
   .Empauthentication-wrapper {
      height: auto;
   }

   .white-panel {
      width: min(500px, 95%);
      height: auto;
      top: -40px;
      left: 50%;
      transform: translate(-50%, -15%);
      margin: 0 auto;
   }

   .register-info {
      margin-top: 300px;
   }

   .white-panel.register-active {
      top: 130px;
      transform: translate(-50%, 0);
   }
}

@media (max-width: 767.98px) {
   :is(.login-info, .register-info) button {
      width: 75%;
   }
}