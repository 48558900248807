.phoneNumber-wrapper {
   border-radius: 0.25rem;
   border: 1.5px solid var(--color-grey);
   user-select: none;
}

.phoneNumber-input {
   overflow: hidden;
   outline: none;
   border: none;
   background-color: transparent;
   padding: 0.75rem 1.25rem;
   min-height: 3.5rem;
}

.phoneNumber-label {
   pointer-events: none;
   user-select: none;
}

.phoneNumber-wrapper:has(.phoneNumber-input:focus) {
   border: 2px solid var(--color-pri-blue);
}

.phoneNumber-wrapper:has(.phoneNumber-input:focus)~.floating-label {
   top: 0;
   left: 1rem;
   z-index: 5;
   font-size: 0.875rem;
   font-weight: 500;
   color: var(--color-pri-blue);
   transition: all 0.2s ease-in-out;
}

.phoneNumber-wrapper:has(.phoneNumber-input:not(:placeholder-shown).phoneNumber-input:not(:focus))~.floating-label {
   top: 0;
   left: 1rem;
   z-index: 9;
   font-size: 0.875rem;
   font-weight: 500;
   transition: all 0.2s ease-in-out;
}


.select-country {
   height: 160px;
   background-color: #fff;
   border: 1.5px solid var(--color-grey);
   border-radius: 0.25rem;
   overflow-y: scroll;
   z-index: 100;
   padding: 0;
}

.select-country li {
   padding: 8px;
   border-bottom: 1px solid #ccc;
   cursor: pointer;
}

.select-country li.active {
   background-color: #ccc;
}

.dialing-code {
   position: relative;
   padding-left: 5px;
   padding-right: 15px;
   border-right: 2px solid #b2b1b1;
   cursor: pointer;
}

.dialing-code:after {
   position: absolute;
   content: "";
   top: 43%;
   right: 5%;
   width: 0;
   height: 0;
   border: 5px solid transparent;
   border-color: rgb(96, 95, 95) transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.dialing-code.select-arrow-active:after {
   border-color: transparent transparent rgb(96, 95, 95) transparent;
   top: 7px;
}