tfoot .rdtTimeToggle,
td.rdtSwitch {
  border: 2px solid #ccc;
  cursor: pointer !important;
}

.rdtCounter :last-child {
  margin-top: 6px;
}

.employer-interviews {
  margin: 50px 0;
}

.employer-interviews .interview-list tbody tr:is(:hover, .active) {
  box-shadow: inset 2px 2.1px 1px #005eab, inset -2px -2px 1px #005eab;
  -moz-box-shadow: inset 2px 3px 1px #005eab, inset -2px -2px 1px #005eab;
}

.employer-interviews .btn-viewMore {
  width: 115px !important;
}

.trHeigLight {
  border: 1px solid var(--color-pri-blue);
  background-color: var(--color-pri-blue);
}

/* .interview-list :is(.interview-on, .status) {
    white-space: nowrap;
} */

.employer-interviews .interview-list td {
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
}

.employer-interviews input[type="radio"]:checked + label {
  background-color: var(--color-pri-blue);
  border: 1px solid var(--color-pri-blue);
  color: white;
}

.disabled-image {
  opacity: 0.5;
  /* Reduce opacity to indicate disabled state */
  cursor: not-allowed;
  /* Change cursor to indicate not-allowed interaction */
  pointer-events: none;
}

.text-secondary {
  display: block;
  font-size: 8px;
}

#deleteConfirmationModal .modal-header {
  border-bottom: none;
}

/*datapicker style by Namrata*/
.react-datepicker__time-container {
  ::-webkit-scrollbar {
    height: 4px;
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(5, 3, 68, 0.5);
  }
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.helperModalFontFam {
  font-family: Arial, Helvetica, sans-serif;
}

#helperHireModal .toggle-container {
  display: flex;
  position: relative;
  width: 450px;
  height: 45px;
  background-color: #f0f0f0;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  font-family: Arial, sans-serif;
}
#helperHireModal .invalid {
  border: 2px solid var(--error) !important;
}

.toggle-container .option {
  flex: 1;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.toggle-container .option.active {
  color: white;
}

.toggle-container .slider {
  position: absolute;
  width: 33.33%; /* Adjust based on the number of options */
  height: 100%;
  background-color: #0069d9; /* Red color for the slider */
  border-radius: 25px;
  transition: transform 0.3s ease; /* Smooth sliding effect */
  z-index: 0;
}

.toggle-container .slider.New {
  transform: translateX(0%);
}

.toggle-container .slider.Transfer {
  transform: translateX(100%);
}
.toggle-container .slider.Ex-Singapore {
  transform: translateX(200%);
}

.employer-interviews input[type="radio"] {
  display: none;
}

.option:has(input[type="radio"]:checked) {
  border-color: #0069d9;
  background: #0069d9;
}
#viewDetailsModal .candidate-info .description-1,
.candidate-details .description-1 {
  background: #005eab !important;
}

.option:has(input[type="radio"]:checked) span {
  color: #fff;
}

#helperHireModal button {
  width: 160px;
}

#helperHireModal .message-box {
  border: 1.5px solid var(--color-pri-blue);
  border-radius: 8px;
  padding: 30px;
  position: relative;
}

#helperHireModal .message-box .box-label {
  position: absolute;
  top: 0;
  left: 30px;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 0 5px;
}

.check-group {
  background: #fff;
  max-width: 13rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 10, 0.2);
  counter-reset: total;
  counter-reset: checked;
}

.check-group > * + * {
  margin-top: 0.75rem;
}

.check-group .checkbox {
  counter-increment: total;
}

.check-group input[type="checkbox"]:checked {
  counter-increment: checked;
}

.check-group__result {
  font-weight: bold;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.check-group__result:after {
  content: counter(checked) " / " counter(total);
  padding-left: 1ch;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox__input {
  position: absolute;
  width: 1.375em;
  height: 1.375em;
  opacity: 0;
  cursor: pointer;
}

.checkbox__input:checked + .checkbox__icon .tick {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  width: 1.375em;
  height: 1.375em;
  flex-shrink: 0;
  overflow: visible;
}

.checkbox__icon .tick {
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  transition: stroke-dashoffset 0.2s ease-out;
}

.checkbox__label {
  margin-left: 0.5em;
}

.confirmationBox .btn {
  border-radius: 2px;
}

.empInterviewViewDetailsModal .profile-wrapper .profile {
  position: unset;
  transform: unset;
}

.empInterviewViewDetailsModal .candidate-info .description-2 {
  font-size: 18px;
  font-weight: 500;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 767px) {
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__time-container {
    float: none;
    width: 100%;
    border-top: 1px solid #aeaeae;
    /*margin-bottom: 2px;*/
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    margin: 0px;
    width: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    display: flex;
    width: 300px;
    overflow: scroll;
    height: 45px !important;
    overflow-y: hidden;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 45px;
  }

  .empInterviewViewDetailsModal .candidate-info .profile-wrapper {
    padding: 10px 0;
  }
  .empInterviewViewDetailsModal .candidate-info .name {
    padding-top: 0;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 40em) {
  .responsiveTable :is(td.profile-wrapper, td.srNo) {
    display: flex;
    justify-content: center;
  }

  .responsiveTable td.srNo .tdBefore {
    text-align: center !important;
  }
}

@media (max-width: 575.98px) {
  #helperHireModal {
    margin-top: 1rem;
  }
}

@media (max-width: 491.98px) {
  .empInterviewViewDetailsModal .candidate-details .description-2 {
    grid-template-columns: 1fr;
    text-align: center;
  }

  #helperHireModal .toggle-container {
    flex-direction: column;
    width: 150px;
    height: auto;
  }

  .toggle-container .slider {
    width: 100%;
    height: 50px;
  }

  .toggle-container .slider.Transfer {
    transform: translateY(50px);
  }
  .toggle-container .slider.Ex-Singapore {
    transform: translateY(100px);
  }
}
