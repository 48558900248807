.icon-view {
   color: var(--color-pri-blue);
}

.icon .text {
   display: block;
   font-size: 9px;
   font-weight: 600;
   letter-spacing: .3px;
   white-space: nowrap;
}

@media (max-width: 575.98px) {
   .offerList {
      max-width: 90vw;
   }
}