.w-138{
   width: 150px !important;
}
.cndOffer .interview-list .icon img {
   width: 38px;
}

.mobile-only {
   display: none;
 }
 
 .desktop-only {
   display: block;
 }

.cndOffer .interview-list tbody tr:is(:hover, .active) {
   box-shadow: inset 2px 2.1px 1px var(--color-pri-red),
      inset -2px -2px 1px var(--color-pri-red);
   -moz-box-shadow: inset 2px 3px 1px var(--color-pri-red),
      inset -2px -2px 1px var(--color-pri-red);
}

.cndOffer .interview-list td {
   vertical-align: middle;
   word-wrap: break-word;
   white-space: normal;
}

.cndOffer .interview-list .icon .text {
   font-size: 9px;
}

.cndOffer .interview-list .icon1 {
   color: rgb(39, 174, 96);
}

.cndOffer .interview-list .icon2 {
   color: #007bff;
}

.draggableSignature {
   z-index: 1;
   border: 1px solid black;
}

.react-pdf__Page__canvas {
   width: 100% !important;
   height: unset !important;
}

.icon :is(.download, .printer) {
   width: 40px;
}


.scontainer {
   width: 100%;
   /* height: 100vh; */
}

.sigContainer {
   width: max(90%, 260px);
   margin: 0 auto;
   background-color: #fff;
}

.signature-wrapper{
   height: 200px;
   position: relative;
}

.sigPad {
   width: 100%;
   height: 100%;
   border: 1px solid #ccc;
   position: absolute;
}

.close-icon-blue {
   background: black;
   color: var(--color-white);
   font-size: 30px;
   margin: 0;
   padding: 7px;
   border-radius: 50%;
   z-index: 2;
   top: 0;
   right: 0;
   transform: translate(35%, -30%);
}

.sign-label {
   display: block;
   background-color: var(--color-white);
   color: #0d6efd;
   font-size: 20px;
   font-weight: 450;
   text-align: center;
}

#signaturePadModal {
   width: min(700px, 95%);
}

.cndOffer input[type=radio]:checked+label {
   background-color: #F04DA1;
   color: white;
}

/*datapicker style by Namrata*/

.react-datepicker__time-container {
   ::-webkit-scrollbar {
      height: 4px;
      width: 7px;
   }

   ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
   }

   ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(5, 3, 68, 0.5);
   }

}

.react-datepicker__time-container {
   border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
   display: none;
}

.react-datepicker__navigation--previous {
   left: unset;
   right: 140px;
}

/* new code added by Namrata */
/* .cndOffer .btn {
   border:1px solid #F04DA1;
} */

/* .cndOffer .btn:ho {
   color: white;
   background-color: #F04DA1;
   border-color: #F04DA1;
} */
/* Media query for screens with a maximum width of 768px */
@media (max-width: 767px) {

   .mobile-only {
      display: block;
    }
  
    .desktop-only {
      display: none;
    }
   .react-datepicker {
      display: flex;
      flex-direction: column;
   }

   .react-datepicker__month-container {
      float: none;
   }

   .react-datepicker__time-container {
      float: none;
      width: 100%;
      border-top: 1px solid #aeaeae;

      /*margin-bottom: 15px;*/
   }

   .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      margin: 0px;
      width: 100%;
   }

   .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      display: flex;
      width: 300px;
      overflow: scroll;
      height: 45px !important;
      overflow-y: hidden;
   }

   .react-datepicker__time-container .react-datepicker__time {
      height: 45px;
   }

}